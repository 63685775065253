import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import location from "../icons/location.svg";
import pin from "../icons/pin.svg";
import mail from "../icons/mail.svg";
import phone from "../icons/phone.svg";

import global from "../icons/global_icon.svg";

import twitter from "../icons/twitter.svg";
import linkedin from "../icons/linkedin.svg";
import facebook from "../icons/facebook.svg";
import instagram from "../icons/instagram.svg";

import { motion } from "framer-motion";
import Validation from "./Validation";

const FadeInAnimationFooter = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.15 * index,
      duration: 0.35,
    },
  }),
};

export default function Footer() {
  const url = useLocation().pathname;
  useEffect(() => {
    setErrors({})
  }, [url])


  const inputFieldStyle = "rounded-md p-1 text-sm w-full";

  const footerSocials = [
    {
      src: twitter,
      link: "",
    },
    {
      src: linkedin,
      link: "",
    },
    {
      src: facebook,
      link: "",
    },
    {
      src: instagram,
      link: "",
    },
  ];
  const [formData, setFormData] = useState({
    name: "",
    contactNo: "",
    email: "",
    organization: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error when the field value changes
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (formData.name.trim() === "") {
      errors.name = "Name is required";
    }

    if (formData.contactNo.trim() === "") {
      errors.contactNo = "Contact number is required";
    } else if (!/^\d{10}$/.test(formData.contactNo)) {
      errors.contactNo = "Invalid mobile number";
    }

    if (formData.email.trim() === "") {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (formData.organization.trim() === "") {
      errors.organization = "Organization name is required";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const response = await axios.post(
        "https://api.techhivetechnology.com/send-email",
        formData
      );

      if (response.status === 200) {
        alert("Your message has been sent!");
        setFormData({
          name: "",
          contactNo: "",
          email: "",
          organization: "",
        });
        setErrors({});
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      alert("Something went wrong. Please try again.");
    }
  };
  return (
    <div className="flex flex-col justify-between bg-header-bg-black text-white font-inter">
      <div className="flex flex-col lg:flex-row gap-10 px-4 py-8 mx-4 items-start justify-evenly bg-header-bg-black text-white border-b border-white">
        <div className="flex flex-col justify-evenly md:flex-row gap-6">
          <div className="flex flex-col gap-4">
            <h2 className="text-lg">Address</h2>
            <div className="flex gap-2 items-center">
              <img src={location} alt="" className="h-6" />
              <p className="text-sm w-3/4">
                Plot No.11, 24th, 2 Nd Cross Street, Kandan Avenue JB Estate,
                Avadi
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <img src={pin} alt="" className="h-6" />
              <p className="text-sm">Chennai, Tamil Nadu, India 600054</p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="text-lg">Contact Info</h2>
            <div className="flex gap-2 items-center">
              <img src={mail} alt="" className="h-4" />
              <a href="mailto:techhive12.demo@gmail.com" className="text-sm hover:text-[#EC568E] transition-all duration-150">
                techhive12.demo@gmail.com
              </a>
            </div>
            <div className="flex gap-2 items-center md:pt-[20px]">
              <img src={phone} alt="" className="h-5" />
              <a href="tel:+91 8925799512" className="text-sm hover:text-[#EC568E] transition-all duration-150">
                +91 8925799512
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <h2 className="text-lg">Book Free Demo</h2>
          {/* <form onSubmit={handleSubmit}  className="flex flex-col gap-6 text-black">
            <div className="flex gap-4">
              <div className=" flex flex-col justify-start ">
              <input
                type="text"
                placeholder="Your name *"
                value={formData.name}
                onChange={handleChange} 
                className={inputFieldStyle}
              />
            <Validation error={errors.name} />

              </div>
              <div className=" flex flex-col justify-start ">

              <input
                type="text"
                placeholder="Your contact number *"
                value={formData.contactNo}
                onChange={handleChange}
                className={inputFieldStyle}
              />
            <Validation error={errors.contactNo} />
            </div>
            </div>

            <div className="flex gap-4">
              <div className=" flex flex-col justify-start ">
              <input
                type="text"
                placeholder="Your email *"
                value={formData.email}
                onChange={handleChange}
                className={inputFieldStyle}
              />
            <Validation error={errors.email} />
              </div>
              <div className=" flex flex-col justify-start ">
              <input
                type="text"
                placeholder="Your organization name *"
                value={formData.organization}
                onChange={handleChange}
                className={inputFieldStyle}
              />
            <Validation error={errors.organization} />
            </div>{" "}
            </div>{" "}
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <button
                type="submit"
                className="px-6 py-1 w-fit mx-auto rounded-md bg-footer-button-blue border border-footer-button-blue hover:bg-footer-button-blue/60 hover:border-footer-button-blue/60 text-white transition duration-150"
              >
                Send
              </button>
            </motion.button>
          </form>  */}
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-6 text-black"
          >
            <div className="flex gap-4">
              <div className=" flex flex-col justify-start ">
                <input
                  type="text"
                  name="name"
                  placeholder="Your name *"
                  value={formData.name}
                  onChange={handleChange}
                  className={inputFieldStyle}
                />
                <Validation error={errors.name} />
              </div>
              <div className=" flex flex-col justify-start ">
                <input
                  type="text"
                  name="contactNo"
                  placeholder="Your contact number *"
                  value={formData.contactNo}
                  onChange={handleChange}
                  className={inputFieldStyle}
                />
                <Validation error={errors.contactNo} />
              </div>
            </div>
            <div className="flex gap-4">
              <div className=" flex flex-col justify-start ">
                <input
                  type="text"
                  name="email"
                  placeholder="Your email *"
                  value={formData.email}
                  onChange={handleChange}
                  className={inputFieldStyle}
                />
                <Validation error={errors.email} />
              </div>
              <div className=" flex flex-col justify-start ">
                <input
                  type="text"
                  name="organization"
                  placeholder="Your organization name *"
                  value={formData.organization}
                  onChange={handleChange}
                  className={inputFieldStyle}
                />
                <Validation error={errors.organization} />
              </div>
            </div>
            <motion.div
              className="w-full flex justify-center items-center"
            >
              <button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                type="submit"
                className="px-6 py-1 w-fit  rounded-md bg-[#111DBF] border border-footer-button-blue hover:bg-footer-button-blue/60 hover:border-footer-button-blue/60 text-white transition duration-150"
              >
                Send
              </button>
            </motion.div>
          </form>

          {/* <form onSubmit={handleSubmit} className="flex flex-col gap-6 text-black">
    <div className="flex gap-4">
      <input
        type="text"
        name="name"
        placeholder="Your name *"
        value={formData.name}
        onChange={handleChange}
        className={inputFieldStyle}
      />
      {errors.name && <span>{errors.name}</span>}
      <input
        type="text"
        name="contactNo"
        placeholder="Your contact number *"
        value={formData.contactNo}
        onChange={handleChange}
        className={inputFieldStyle}
      />
      {errors.contactNo && <span>{errors.contactNo}</span>}
    </div>
    <div className="flex gap-4">
      <input
        type="text"
        name="email"
        placeholder="Your email *"
        value={formData.email}
        onChange={handleChange}
        className={inputFieldStyle}
      />
      {errors.email && <span>{errors.email}</span>}
      <input
        type="text"
        name="organization"
        placeholder="Your organization name *"
        value={formData.organization}
        onChange={handleChange}
        className={inputFieldStyle}
      />
      {errors.organization && <span>{errors.organization}</span>}
    </div>
    <button type="submit">Send</button>
  </form> */}
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-6 justify-evenly px-4 py-4">
        <div className="flex flex-wrap justify-center md:flex-row md:items-start gap-6 text-sm">
          <Link to="" className="flex gap-1 items-center">
            <img src={global} alt="icon" className="h-5" />
            English
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
            </svg> */}
          </Link>
          <Link to="">Privacy Policy</Link>
          <Link to="">Terms</Link>
          <Link className=" lg:block hidden" to="">Cookie Settings</Link>
        </div>

        <div className="text-sm text-center">
          <p>© 2023 Tech Hive Technology. All rights reserved.</p>
        </div>
        <div className="flex items-center gap-6">
          {footerSocials.map((content, index) => {
            return (
              <motion.div
                key={index}
                variants={FadeInAnimationFooter}
                initial="initial"
                whileInView="animate"
                viewport={{
                  once: true,
                }}
                custom={index}
              >
                <Link to={content.link}>
                  <img src={content.src} alt="img" className="h-4" />
                </Link>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
